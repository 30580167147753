/* Write your custom CSS here */
:root {
  --gradient-background: linear-gradient(90deg, #f26118 0%, #9f0fed 100%);
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #262635;
}

.page-sidebar .accordion-menu::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.collapse::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 2px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e2cad9;
}

.followDiv::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.image {
  opacity: 1;
  display: block;
  width: 38%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.table_image {
  box-shadow: rgba(105, 103, 103, 0) 0px 5px 15px 0px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
  float: left;
  object-fit: cover;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.6;
  cursor: grab;
}

.container:hover .middle {
  opacity: 1;
}

.cursor {
  cursor: pointer;
}

.page-sidebar .accordion-menu>li>span {
  display: block;
  color: #9a9cab;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  line-height: 45px;
  padding: 0 15px;
  text-decoration: none;
  cursor: pointer;
}

.page-sidebar .accordion-menu>li>span>svg {
  width: 21px;
  height: 21px;
  line-height: 40px;
  text-align: center;
  vertical-align: text-top;
  color: #9a9cab;
  margin-right: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidenav__icon {
  margin-right: 15px;
}

.card-overflow {
  overflow-x: scroll;
}

.card-overflow::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.card-overflow::-webkit-scrollbar-track {
  background: #fff;
}

.card-overflow::-webkit-scrollbar-thumb {
  background: #1f1f2b;
}

.back__style {
  width: "100%";
  height: "100%";
  background-size: "cover";
  background-attachment: "fixed";
  margin: 0;
  padding: 0;
  background-image: url("../images/login.png");
}

.icon_margin {
  margin-left: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #86c1ed;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #ada6f2;
}

input:focus+.slider {
  box-shadow: 0 0 1px #ada6f2;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.searchBar:focus {
  background-color: #1f1f2b;
  box-shadow: none;
}

.MuiTypography-colorInherit {
  color: #91a3b0 !important;
}

.MuiSelect-select {
  color: #91a3b0 !important;
}

.MuiSelect-icon {
  color: #91a3b0 !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #9a9cab !important;
}

.MuiIconButton-root {
  color: #000 !important;
}

.float__right {
  float: right;
}

.MuiPaper-root {
  background-color: #332741 !important;
  border: 2px solid #80176A !important;
  border-radius: 30px !important;
}

.text-gray {
  color: #91a3b0 !important;
}

.text-red {
  color: #de1738;
}

.collapse-margin {
  margin-left: -7px;
  /* overflow-x: scroll; */
}

.pointer-cursor {
  cursor: pointer;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: aliceblue;
  color: #fff;
}

.post-padding {
  padding: 6px 15px !important;
  margin-bottom: 0;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  height: 10px;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #fff;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */

input[type="range"]::-moz-range-track {
  height: 15px;
  background: #fff;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  /* background: var(--gradient-background); */
  /* border-radius: 50%; */
  /* border: 2px solid #f50; */
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #9f0fed !important;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid black;
  /*  slider progress trick  */
  /* box-shadow: -407px 0 0 400px #f50; */
}

/*=============
Aesthetics 
=========================*/

body {
  font-family: Lexend;
}

h1 {
  color: #4b4949;
  text-align: center;
}

.wrapper {
  color: #4b4949;
  background: #f50;
  max-width: 400px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.range {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  padding: 0px 10px;
}

/* input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
} */

.commentImg {
  margin-top: 13px;
}

.shadow {
  box-shadow: rgb(105 103 103 / 50%) 0px 5px 15px 0px;
  border: 2px solid #e8538f;
}

.comment-delete {
  margin-left: 10px;
}

.form-check-input:focus,
.form-control:focus {
  box-shadow: none;
}

.rows>* {
  max-height: 500px;
}

.pie>* {
  max-height: 350px;
}

.swal-title {
  color: rgba(255, 255, 255, 0.65) !important;
  font-weight: 500 !important;
}

.swal-text {
  color: rgba(255, 255, 255, 0.64) !important;
}

.swal-modal {
  background-color: #161622 !important;
}

.swal-icon--success__hide-corners,
.swal-icon--success:after,
.swal-icon--success:before {
  background-color: transparent;
}

.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {
  background-color: #161622 !important;
}

.chip,
.highlightOption,
.multiSelectContainer li:hover {
  background: #e8538f !important;
}

.page-sidebar .accordion-menu {
  overflow-x: scroll !important;
}

.danger {
  background-color: #f5365c;
}

.success {
  background-color: #146356;
}

.stats-card .stats-icon.change-pink {
  color: #fff;
  background-color: #fc9494;
  box-shadow: 0 3px 18px -8px #fc9494;
}

.border-right-radius {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.pagination {
  /* float: right; */
}

.page-link {
  border-radius: 50% !important;
  padding: 5px 13px;
}

.page-item {
  padding: 5px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #e8538f;
  border-color: #e8538f;
}

.page-link:focus {
  box-shadow: none;
}

.dropdown {
  max-height: 30px;
  margin-top: 15px;
  padding: 3px 5px;
  font-size: 12px;
  max-width: 80px;
}

#datePicker {
  position: absolute;
  z-index: 1;
}

.table {
  position: relative;
}

.rdrDefinedRangesWrapper {
  background: #fff !important;
  color: #9a9cab;
  border-right: solid 1px #9a9cab !important;
}

.rdrStaticRange {
  background: #fff !important;
  color: #9a9cab;
  border-bottom: 1px solid #9a9cab !important;
}

.rdrCalendarWrapper,
.rdrInputRangeInput {
  background: #fff !important;
}

.rdrMonthAndYearPickers select,
.rdrDayPassive .rdrDayNumber span {
  color: #9a9cab !important;
}

.rdrDayNumber span {
  color: #fff !important;
}

.rdrDateRangePickerWrapper {
  border: solid 1px #9a9cab !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #1f1f2b !important;
}

.rdrNextPrevButton,
.rdrDateDisplayItem,
.rdrDateDisplayItem input {
  background: #fff !important;
  color: #000 !important;
}

.rdrDateDisplayItemActive {
  border-color: #fff !important;
}

.rdrDateDisplayWrapper {
  background-color: #1f1f2b !important;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #ada6f2 !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrDayToday .rdrDayNumber span:after {
  background: #ada6f2 !important;
}

.rdrInputRangeInput {
  border: solid 1px #ada6f2 !important;
  color: #fff !important;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: #ada6f2;
  outline: 0;
  color: #fff !important;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px;
  border-color: #ada6f2 !important;
}

.rdrDayEndPreview,
.rdrDayStartPreview {
  border-color: #ada6f2 !important;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #ada6f2 !important;
}

.rdrYearPicker option,
.rdrMonthPicker option {
  background: #262635 !important;
}

.rdrYearPicker select::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.rdrYearPicker select::-webkit-scrollbar-track {
  background: #fff;
}

.rdrYearPicker select::-webkit-scrollbar-thumb {
  background: #262635;
}

.rdrDateDisplayItemActive {
  border: 3px solid #959595 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.styles-module_Editext__input__2-M50,
.styles-module_Editext__button__sxYQX {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;
  color: #fff !important;
  border-color: #fff !important;
}

.hiddenRow {
  padding: 0 !important;
}

.profile-header .profile-img img {
  object-fit: cover;
}

.makeStyles-navLink-1.active {
  font-size: 15px !important;
}

.accordion-toggle {
  border-top-width: 3px;
}

/* ======================== Start DatePicker CSS ======================== */

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  display: none;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 0;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 4px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 14px;
  padding: 12px 16px !important;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
  width: inherit;
  height: inherit;
  border-radius: 1px;
  margin: 0 !important;
}

.daterangepicker .ranges li.active:nth-child(1) {
  background-color: transparent;
  color: black;
}

.daterangepicker .ranges li.active:nth-child(1):hover {
  background-color: #eee !important;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    /* width: 140px; */
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

/* ======================== End DatePicker CSS ======================== */

.disabledBtn {
  background-color: #1f1f2b !important;
  color: #9a9cab !important;
}

@media (max-width: 768px) {
  body {
    font-size: 11px !important;
  }

  .page-sidebar .accordion-menu>li>a {
    line-height: 33px !important;
  }

  .makeStyles-navLink-1.active {
    font-size: 12px !important;
  }

  .btn {
    padding: 6px 14px !important;
  }

  body.sidebar-hidden .page-content {
    margin-left: -45px;
  }

  .page-sidebar {
    width: 190px !important;
  }

  .page-sidebar .logo {
    font-size: 25px;
  }

  .sidenav__icon>svg {
    height: 20px;
    width: 20px;
  }
}

.text-dark {
  color: #000 !important;
}

.income-detail {
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #fff4e8 !important;
}

.inner-income {
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #fffaf4 !important;
}

.agency-detail {
  /* background-color: #372143 !important; */
  padding: 10px !important;
  border-radius: 10px !important;
}

.activeBtn {
  background-color: #de1738 !important;
  outline: none;
  border: none;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  font-size: 14px;
}

.disabledBtn {
  background-color: #241330 !important;
  outline: none;
  border: none;
  color: white;
  font-weight: 700;
}

.accptedbtn {
  background-color: #9aefa9;
  border-radius: 28px;
  color: #26a226;
  padding: 7px;
  outline: none;
  border: none;
}

.crtrbtn {
  height: 16px;
  width: 53px;
  border-radius: 4px;
  outline: none;
  border: none;
  color: white;
  font-size: 8px;
}

.agency-invitation {
  border-radius: 10px !important;
}

.bg-gray {
  background-color: #f1f1f1 !important;
}

.activeAgency {
  border-bottom: 3px solid #000 !important;
}

.react-datepicker__input-container>input {
  width: 70px;
  text-align: center !important;
  border-radius: 10px !important;
  border: 1px solid #000 !important;
}

.react-datepicker-popper {
  position: absolute !important;
  left: -65px !important;
  top: 43px !important;
  transform: translate(189.5px, 102.5px) !important;
  will-change: transform !important;
}

.cursor-none {
  cursor: none !important;
}

.main-host {
  /* background-color: linear-gradient(0deg, #6F0FE3 0%, #B513E2 100%) !important; */
  background: url("../images/hostBg.png") !important;
  background-size: cover;
  height: 100% !important;
  background-repeat: no-repeat !important;
  border-radius: 16px;
}

.earning-tab {
  background: url("../images/grendientearning.png") !important;
  background-size: cover;
  height: 150px !important;
  background-repeat: no-repeat !important;
  border-radius: 16px;
}

.text-main {
  color: #ea1a59;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: center;
}

.text-main-12 {
  color: #ea1a59;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: center;
}

.main-settlement {
  border: 1px solid #2c1b38;
  border-radius: 17px;
}

.main-settlement-header {
  background: #2c1b38;
  border-radius: 17px 17px 0px 0px;
  position: relative;
}

.date-content {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #d5d5d5;
}

.dollar-content {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: center;
  color: #eab31a;
}

.text-Light {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #dbdbdb;
}

.status-content {
  border-radius: 4px 63px 4px 4px;
  background: #c2174e;
  width: 48.38px;
  height: 18px;
  color: #ffffff;
  font-family: Lexend;
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
}

.status-content-paid {
  border-radius: 4px 63px 4px 4px;
  background: #395a52;
  width: 48.38px;
  height: 18px;
  color: #ffffff;
  font-family: Lexend;
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
}

.form-select {
  border-color: #2d2d3f, !important;
  background: transparent !important;
  color: #9a9cab;
  border-radius: 3px;
  padding: 0.65rem 1rem;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-8c1w3n-menu {
  height: 100px !important;
  overflow: scroll !important;
}

.css-qbdosj-Input,
.css-166bipr-Input {
  color: #fff !important;
}

.btn-main {
  background-color: #F3275E;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
}

.btn-main1 {
  background-color: #B2B2B2;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
}
.css-1hb7zxy-IndicatorsContainer{
  display: none !important;
}


/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}
/* Hide default HTML checkbox */
.switch input {display:none;}
/* The slider */
.switch .slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ebedf2;
-webkit-transition: .4s;
transition: .4s;
}
.switch .slider:before {
position: absolute;
content: "";
background-color: white;
-webkit-transition: .4s;
-ms-transition: .4s;
transition: .4s;
height: 14px;
width: 14px;
left: 2px;
bottom: 2px;
box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}
.switch input:checked + .slider:before {
-webkit-transform: translateX(17px);
-ms-transform: translateX(17px);
transform: translateX(17px)
}
/* Rounded Slider Switches */
.switch .slider.round { border-radius: 34px; }
.switch .slider.round:before { border-radius: 50%; }
.switch.s-secondary input:checked + .slider { background-color: #805dca; }
.switch.s-secondary input:focus + .slider { box-shadow: 0 0 1px #805dca; }



/*
==================
    Switches
==================
*/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #888ea8;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  background-color: white;
  -webkit-transition: .4s;
  -ms-transition: .4s;
  transition: .4s;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px)
}

/* Rounded Slider Switches */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/* Solid Switches*/

.switch.s-default .slider:before {
  background-color: #d3d3d3;
}

.switch.s-primary .slider:before {
  background-color: #4361ee;
}

.switch.s-success .slider:before {
  background-color: #1abc9c;
}

.switch.s-warning .slider:before {
  background-color: #e2a03f;
}

.switch.s-danger .slider:before {
  background-color: #e7515a;
}

.switch.s-secondary .slider:before {
  background-color: #805dca;
}

.switch.s-info .slider:before {
  background-color: #2196f3;
}

.switch.s-dark .slider:before {
  background-color: #3b3f5c;
}

.switch input:checked+.slider:before {
  background-color: #fafafa;
}

.switch.s-default input:checked+.slider {
  background-color: #888ea8;
}

.switch.s-default input:focus+.slider {
  box-shadow: 0 0 1px #ebedf2;
}

.switch.s-primary input:checked+.slider {
  background-color: #4361ee;
}

.switch.s-primary input:focus+.slider {
  box-shadow: 0 0 1px #4361ee;
}

.switch.s-success input:checked+.slider {
  background-color: #1abc9c;
}

.switch.s-success input:focus+.slider {
  box-shadow: 0 0 1px #1abc9c;
}

.switch.s-warning input:checked+.slider {
  background-color: #e2a03f;
}

.switch.s-warning input:focus+.slider {
  box-shadow: 0 0 1px #e2a03f;
}

.switch.s-danger input:checked+.slider {
  background-color: #e7515a;
}

.switch.s-danger input:focus+.slider {
  box-shadow: 0 0 1px #e7515a;
}

.switch.s-secondary input:checked+.slider {
  background-color: #805dca;
}

.switch.s-secondary input:focus+.slider {
  box-shadow: 0 0 1px #805dca;
}

.switch.s-info input:checked+.slider {
  background-color: #2196f3;
}

.switch.s-info input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch.s-dark input:checked+.slider {
  background-color: #4361ee;
}

.switch.s-dark input:focus+.slider {
  box-shadow: 0 0 1px #4361ee;
}

/* Outline Switches */
.switch.s-outline .slider {
  border: 2px solid #4361ee;
  background-color: transparent;
  width: 36px;
  height: 19px;
}

.switch.s-outline .slider:before {
  height: 13px;
  width: 13px;
}

.switch.s-outline[class*="s-outline-"] .slider:before {
  bottom: 1px;
  left: 1px;
  border: 2px solid #4361ee;
  background-color: #4361ee;
  color: #ebedf2;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-icons.s-outline-default {
  color: #d3d3d3;
}

.switch.s-icons.s-outline-primary {
  color: #4361ee;
}

.switch.s-icons.s-outline-success {
  color: #1abc9c;
}

.switch.s-icons.s-outline-warning {
  color: #e2a03f;
}

.switch.s-icons.s-outline-danger {
  color: #e7515a;
}

.switch.s-icons.s-outline-secondary {
  color: #805dca;
}

.switch.s-icons.s-outline-info {
  color: #2196f3;
}

.switch.s-icons.s-outline-dark {
  color: #3b3f5c;
}

.switch.s-outline-default input:checked+.slider {
  border: 2px solid #888ea8;
}

.switch.s-outline-default input:checked+.slider:before {
  border: 2px solid #888ea8;
  background-color: #888ea8;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-outline-default input:focus+.slider {
  box-shadow: 0 0 1px #ebedf2;
}

.switch.s-outline-primary input:checked+.slider {
  border: 2px solid #4361ee;
  background-color: transparent;
}

.switch.s-outline-primary input:checked+.slider:before {
  border: 2px solid #4361ee;
  background-color: #4361ee;
  box-shadow: 0 1px 15px 1px rgba(81, 40, 104, 0.34);
}

.switch.s-outline-primary input:focus+.slider {
  box-shadow: 0 0 1px #b693b1;
}

.switch.s-outline-success input:checked+.slider {
  border: 2px solid #1abc9c;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c;
  background-color: #1abc9c;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-success input:focus+.slider {
  box-shadow: 0 0 1px #1abc9c;
}

.switch.s-outline-warning input:checked+.slider {
  border: 2px solid #e2a03f;
}

.switch.s-outline-warning input:checked+.slider:before {
  border: 2px solid #e2a03f;
  background-color: #e2a03f;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-warning input:focus+.slider {
  box-shadow: 0 0 1px #e2a03f;
}

.switch.s-outline-danger input:checked+.slider {
  border: 2px solid #e7515a;
}

.switch.s-outline-danger input:checked+.slider:before {
  border: 2px solid #e7515a;
  background-color: #e7515a;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-danger input:focus+.slider {
  box-shadow: 0 0 1px #e7515a;
}

.switch.s-outline-secondary input:checked+.slider {
  border: 2px solid #805dca;
}

.switch.s-outline-secondary input:checked+.slider:before {
  border: 2px solid #805dca;
  background-color: #805dca;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-secondary input:focus+.slider {
  box-shadow: 0 0 1px #805dca;
}

.switch.s-outline-info input:checked+.slider {
  border: 2px solid #2196f3;
}

.switch.s-outline-info input:checked+.slider:before {
  border: 2px solid #2196f3;
  background-color: #2196f3;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-info input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch.s-outline-dark input:checked+.slider {
  border: 2px solid #3b3f5c;
}

.switch.s-outline-dark input:checked+.slider:before {
  border: 2px solid #3b3f5c;
  background-color: #3b3f5c;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-dark input:focus+.slider {
  box-shadow: 0 0 1px #3b3f5c;
}


/*  Icons Switches */

.switch.s-icons {
  width: 57px;
  height: 30px;
}

.switch.s-icons .slider {
  width: 48px;
  height: 25px;
}

.switch.s-icons .slider:before {
  vertical-align: sub;
  color: #3b3f5c;
  height: 19px;
  width: 19px;
  line-height: 1.3;
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23e9ecef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
}

.switch.s-icons input:checked+.slider:before {
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
  vertical-align: sub;
  color: #fff;
  line-height: 1.4;
}

.switch.s-icons input:checked+.slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
